<template>
  <b-card-code>
    <div class="table-responsive mb-4" v-if="admission_schedule.id">
      <table class="table table-hover table-bordered">
        <thead>
          <tr>
            <td style="text-align:center">Admission Start</td>
            <td style="text-align:center">Admission End</td>
            <td style="text-align:center">Payment Last Date</td>
            <td style="text-align:center">Admit card Download Start</td>
            <td style="text-align:center">Admit card Download End</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style="text-align:center">
              {{moment(admission_schedule.admission_start).format('DD-MM-Y')}}
            </td>
            <td style="text-align:center">
              {{moment(admission_schedule.admission_end).format('DD-MM-Y')}}
            </td>
            <td style="text-align:center">
              {{moment(admission_schedule.payment_last_time).format('DD-MM-Y')}}
            </td>
            <td style="text-align:center">
              {{moment(admission_schedule.admitcard_start).format('DD-MM-Y')}}
            </td>
            <td style="text-align:center">
              {{moment(admission_schedule.admitcard_end).format('DD-MM-Y')}}
            </td>
          </tr>
        </tbody>
      </table>

    </div>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form ref="institute_form">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Admission Start Date')}}</span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="admission start"
                  :rules="admission_schedule.id?'':'required'"
              >
                <b-form-datepicker
                    v-model="admission_start"
                    name="admission_start"
                    :state="errors.length > 0 ? false:null"
                    placeholder="select a date"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Admission End Date')}}</span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="admission end"
                  :rules="admission_schedule.id?'':'required'"
              >
                <b-form-datepicker
                    v-model="admission_end"
                    name="admission_end"
                    :state="errors.length > 0 ? false:null"
                    placeholder="select a date"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Payment last date')}}</span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="payment last date"
                  :rules="admission_schedule.id?'':'required'"
              >
                <b-form-datepicker
                    v-model="payment_last_time"
                    name="payment_last_time"
                    :state="errors.length > 0 ? false:null"
                    placeholder="select a date"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Admitcard download start date')}}</span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="admit card download start date"
                  :rules="admission_schedule.id?'':'required'"
              >
                <b-form-datepicker
                    v-model="admitcard_start"
                    name="admitcard_start"
                    :state="errors.length > 0 ? false:null"
                    placeholder="select a pdf"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Admitcard download end date')}}</span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  name="admit card download last date"
                  :rules="admission_schedule.id?'':'required'"
              >
                <b-form-datepicker
                    v-model="admitcard_end"
                    name="admitcard_end"
                    :state="errors.length > 0 ? false:null"
                    placeholder="select a date"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- submit button -->
          <b-col md="4" v-if="$can('schedule','Admission')">
            <b-button class="mt-3"
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
            >
              Update
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BFormGroup,BFormDatepicker,
  BForm,BRow, BCol,BCardText
} from 'bootstrap-vue'
import {required} from '@validations'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
import moment from "moment"
export default {
  name:'AdmissionSchedule',
  components: {
    BCardCode,
    BFormGroup,
    BCardText,
    BButton,BFormDatepicker,
    BForm,BRow, BCol,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      moment,
      admission_start:null,
      admission_end:null,
      payment_last_time:null,
      admitcard_start:null,
      admitcard_end:null,
      required,
      pageLength: 5,
      dir: false,
    }
  },
  methods:{
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData(this.$refs.institute_form);
          apiCall.post('/admission/schedule/information/storeOrUpdate',data).then((response)=>{
            this.$store.dispatch('GET_ALL_ADMISSION_SCHEDULE');
            this.admission_start=null;
            this.admission_end=null;
            this.payment_last_time=null;
            this.admitcard_start=null;
            this.admitcard_end=null;
            this.$refs.simpleRules.reset();
            this.$toaster.success(response.data.message);
          }).catch((error)=>{
            if (error.response.status ==422) {
              Object.keys(error.response.data.errors).map((field) => {
                this.$toaster.error(error.response.data.errors[field][0]);
              })
            }
            else this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    imgShow(path) {
      return process.env.VUE_APP_ENV_RESOURCHURL + path;
    },
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters(['admission_schedule']),
  },
  created() {
    this.$store.dispatch('GET_ALL_ADMISSION_SCHEDULE');
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>